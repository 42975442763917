<template>
  <v-app
    id="app"
    :style="{
      background: whiteBackground
        ? $vuetify.theme.themes[theme].backgroundWhite
        : $vuetify.theme.themes[theme].background,
    }"
  >
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
    <div>
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default {
  data() {
    return {
      whiteBackground: false,
    };
  },
  mounted() {
    this.whiteBackground = this.$route.path === '/license';
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
};
</script>
<style lang="scss">
@import './src/sass/mgl.scss';
</style>
