import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Licenses from '../views/Licenses.vue';
import PathNotFound from '../views/PathNotFound.vue';
import LicenseSheet from '../views/LicenseSheet.vue';

// Catch redundant navigation errors
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/licenseSheet',
    name: 'licenseSheet',
    component: LicenseSheet,
  },
  {
    path: '/license',
    name: 'license',
    component: Licenses,
  },

  { path: '/:pathMatch(.*)*', name: '404', component: PathNotFound },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
