<template>
  <v-card>
    <v-app-bar color="primary" flat tile height="80"
      ><a id="page-refresh-link" href="/">
        <v-img src="/fig-logo.png" max-height="60" max-width="120" contain> </v-img
      ></a>
      <v-spacer></v-spacer>
      <LanguageSelector :light="false" @hook:initialzied="selectorMounted" />
    </v-app-bar>
  </v-card>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';

export default {
  name: 'Header1',
  components: {
    LanguageSelector,
  },
  methods: {
    selectorMounted() {
      this.$emit('hook:initialzied');
    },
  },
  mounted() {
    // Set the page refresh link to have the same query string as the current page
    const pageRefreshLink = document.getElementById('page-refresh-link');
    if (pageRefreshLink) {
      pageRefreshLink.href = `${pageRefreshLink.href}${window.location.search}`;
    }
  },
};
</script>
