<template>
  <v-card>
    <v-app-bar color="primaryWhite" flat tile height="80"
      ><a href="https://mindforcegamelab.com" target="_blank">
        <v-img src="/logo-black.png" max-height="60" max-width="200" contain> </v-img
      ></a>
      <v-spacer></v-spacer>
      <LanguageSelector :light="true" @hook:initialzied="selectorMounted" />
    </v-app-bar>
  </v-card>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';

export default {
  name: 'Header2',
  components: {
    LanguageSelector,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  methods: {
    selectorMounted() {
      this.$emit('hook:initialzied');
    },
  },
};
</script>

<style scoped>
.v-btn .v-btn--active {
  background-color: white;
  color: white;
}
</style>
