<template>
  <div>
    <v-container fluid fill-height>
      <v-row>
        <v-col>
          <v-card color="primary" flat class="pa-3">
            <v-card-title>
              {{ $t('header') }}
            </v-card-title>
            <v-card-text>
              {{ $t('text') }}
            </v-card-text>
            <v-card-actions>
              <v-btn
                to="/"
                x-large
                depressed
                block
                rounded
                color="secondary"
                class="text-center initial"
                >{{ $t('back') }}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>
