<template>
  <div id="home" :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-container fluid fill width style="padding: 0px">
      <Header1 @hook:initialzied="headerInitialized" />
      <v-row v-if="visible">
        <v-col class="text-center">
          <h2>{{ $t('download') }}</h2>
          <v-container>
            <v-row v-if="iOSAvailable">
              <v-col
                class="
                  d-flex
                  align-center
                  flex-column
                  mb-3
                  col-10
                  offset-1
                  col-md-6
                  offset-md-3
                  col-lg-2
                  offset-lg-5
                "
              >
                <a :href="iosDownloadUrl">
                  <v-img src="/ios.png" max-width="70px" class="mb-5"></v-img>
                </a>
                <qrcode-vue :value="iosDownloadUrl" :size="200" />
                <br />
                <v-btn
                  :href="iosDownloadUrl"
                  x-large
                  rounded
                  color="secondary"
                  class="initial"
                  type="submit"
                >
                  {{ $t('downloadIos') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="androidAvailable">
              <v-col
                class="
                  d-flex
                  align-center
                  flex-column
                  mb-3
                  col-10
                  offset-1
                  col-md-6
                  offset-md-3
                  col-lg-2
                  offset-lg-5
                "
              >
                <v-img src="/android.png" max-width="70px" class="mb-5"></v-img>
                <qrcode-vue :value="androidDownloadUrl" :size="200" />
                <br />
                <v-btn
                  :href="androidDownloadUrl"
                  x-large
                  rounded
                  color="secondary"
                  type="submit"
                  class="initial"
                  >{{ $t('downloadAndroid') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="
                  d-flex
                  align-center
                  flex-column
                  col-10
                  offset-1
                  col-md-6
                  offset-md-3
                  col-lg-2
                  offset-lg-5
                "
              >
              </v-col>
            </v-row>
            <v-row v-if="androidAvailable">
              <v-col
                class="
                  d-flex
                  align-center
                  flex-column
                  mb-3
                  col-10
                  offset-1
                  col-md-6
                  offset-md-3
                  col-lg-2
                  offset-lg-5
                "
              >
                <v-alert
                  class="mb-5 text-left"
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  {{ $t('alerts.huawei') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <PageLoadingCircle v-else :light="false" />
      <Footer />
    </v-container>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue';
import Footer from '../components/Footer.vue';
import Header1 from '../components/Header1.vue';
import PageLoadingCircle from '../components/PageLoadingCircle.vue';
import { getStringConfig } from '../utils/config';

const livsGroupId = 'v44s3rdz276ikrln';
const vclsGroupId = '7wb89o0c0st7lqgw';
const qaGroupId = 'h0v7bta2kdlphb8i';
const ukIrelandGroupId = '04v0ebu667hfubed';
const betaGermanyGroupId = 'es59kc3mb8j4ge2r';
const betaFranceGroupId = '240u81jq7p3j8ql2';
const betaUSGroupId = 'l49cjqrh7b5vgt1v';
const mglUserGroupId = 'hbdj8rp8otbucpv7';

export default {
  name: 'Home',
  components: {
    QrcodeVue,
    Footer,
    Header1,
    PageLoadingCircle,
  },
  data() {
    return {
      iosDownloadUrl: '',
      androidDownloadUrl: '',
      visible: false,
      iOSAvailable: false,
      androidAvailable: true,
      evergreenGroup: false,
    };
  },
  methods: {
    headerInitialized() {
      this.visible = true;
      if (this.evergreenGroup) {
        this.updateAvailablePlatforms();
      }
    },
    isEvergreenGroup(group) {
      if (!group) {
        return true;
      }
      switch (group) {
        case livsGroupId:
        case vclsGroupId:
        case qaGroupId:
        case ukIrelandGroupId:
        case betaGermanyGroupId:
        case betaFranceGroupId:
        case betaUSGroupId:
        case mglUserGroupId:
          return false;
        default:
          return true;
      }
    },
    getIosDownloadUrl(group) {
      if (!group) {
        return process.env.VUE_APP_IOS_DOWNLOAD_URL_DEFAULT;
      }

      switch (group) {
        case livsGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_LIVS1;
        case vclsGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_VCLS;
        case qaGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_QA;
        case ukIrelandGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_UKIRELAND;
        case betaGermanyGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_BETAGERMANY;
        case betaUSGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_BETAUS;
        case betaFranceGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_BETAFRANCE;
        case mglUserGroupId:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_MGLUSER;
        default:
          return process.env.VUE_APP_IOS_DOWNLOAD_URL_DEFAULT;
      }
    },
    getAndroidDownloadUrl(group) {
      if (!group) {
        return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_DEFAULT;
      }

      switch (group) {
        case livsGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_LIVS1;
        case vclsGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_VCLS;
        case qaGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_QA;
        case ukIrelandGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_UKIRELAND;
        case betaGermanyGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_BETAGERMANY;
        case betaUSGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_BETAUS;
        case betaFranceGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_BETAFRANCE;
        case mglUserGroupId:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_MGLUSER;
        default:
          return process.env.VUE_APP_ANDROID_DOWNLOAD_URL_DEFAULT;
      }
    },
    setAllPlatformsAvailable() {
      this.iOSAvailable = true;
      this.androidAvailable = true;
    },
    async updateAvailablePlatforms() {
      const availablePlatforms = await getStringConfig('AVAILABLE_PLATFORMS');
      if (availablePlatforms === null) {
        console.error('No available platforms configuration found');
        this.setAllPlatformsAvailable();
        return;
      }
      const platformArr = availablePlatforms.split(',');
      if (
        platformArr.length === 0 ||
        !platformArr.every((platform) => ['ios', 'android'].includes(platform))
      ) {
        console.error('Invalid platform configuration: ', platformArr);
        this.setAllPlatformsAvailable();
        return;
      }

      this.iOSAvailable = platformArr.includes('ios');
      this.androidAvailable = platformArr.includes('android');
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  beforeMount() {
    const { group } = this.$route.query;
    this.evergreenGroup = this.isEvergreenGroup(group);
    if (!this.evergreenGroup) {
      this.setAllPlatformsAvailable();
    }
    this.iosDownloadUrl = this.getIosDownloadUrl(group);
    this.androidDownloadUrl = this.getAndroidDownloadUrl(group);
  },
};
</script>
