import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import router from './router/index';

Vue.config.productionTip = false;
document.title = 'Playtient Web - Download';

new Vue({
  vuetify,
  i18n,
  router,
  render: (h) => h(App),
}).$mount('#app');
