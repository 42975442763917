<template>
  <div id="licenses" :style="{ background: $vuetify.theme.themes[theme].backgroundWhite }">
    <Header2 @hook:initialzied="headerInitialized" />
    <LicenseSheet v-if="visible" />
    <PageLoadingCircle v-else :light="true" />
  </div>
</template>
<script>
import axios from 'axios';
import LicenseSheet from './LicenseSheet.vue';
import Header2 from '../components/Header2.vue';
import PageLoadingCircle from '../components/PageLoadingCircle.vue';

export default {
  name: 'Licenses',
  created() {
    document.title = 'Open Source Licenses';
  },
  components: {
    LicenseSheet,
    Header2,
    PageLoadingCircle,
  },
  data() {
    return {
      languageToggle: [],
      visible: false,
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_LICENSE_LINK)
      // eslint-disable-next-line no-return-assign
      .then((response) => (this.dataList = response.data));
    // Default to English
    this.languageToggle = 0;
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },
  },
  methods: {
    headerInitialized() {
      this.visible = true;
    },
  },
  watch: {
    languageToggle(newValue) {
      this.$i18n.locale = newValue === 0 ? 'sv' : 'en';
    },
  },
};
</script>

<style scoped>
template {
  background-color: white;
}
html {
  background-color: white;
}
</style>
