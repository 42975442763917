<template>
  <div>
    <h2 class="centerh2">{{ $t('mindforceLicenses') }}</h2>
    <h6 class="centerh6">{{ $t('information') }}</h6>
    <div v-for="data in dataList" :key="data.id"></div>
    <div class="grid1">
      <span
        ><strong>{{ $t('name') }}</strong></span
      >
      <span
        ><strong>{{ $t('version') }}</strong></span
      >
      <span
        ><strong>{{ $t('license') }}</strong></span
      >
      <span
        ><strong>{{ $t('url') }}</strong></span
      >
    </div>
    <div v-for="data in dataList" :key="data.id" class="data">
      <div class="grid2">
        <span class="name">{{ data.name }}</span>
        <span class="license">{{ data.version }}</span>
        <span class="version">{{ data.license }}</span>
        <span class="url"
          ><a v-bind:href="data.url" target="_blank"> {{ $t('visit') }}</a></span
        >
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  name: 'LicenseSheet',
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {
    axios
      .get(process.env.VUE_APP_LICENSE_LINK)
      // eslint-disable-next-line no-return-assign
      .then((response) => (this.dataList = response.data));
  },
  created() {
    axios
      .get(process.env.VUE_APP_LICENSE_LINK)
      // eslint-disable-next-line no-return-assign
      .then((response) => (this.dataList = response.data));
  },
};
</script>

<style scoped>
.grid1 {
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-top: 0px solid #c8c8c8;
  border-bottom: 0px solid #c8c8c8;
  background-color: #e9ecef;
  margin: 0 170px 0 170px;
  font-size: 10px;
}
.grid2 {
  display: grid;
  align-self: center;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  border-bottom: 1px solid #c8c8c8;
  background-color: white;
  font-size: 10px;
  margin: 0 170px 0 170px;
}

.grid1 > span {
  padding: 20px 12px;
  color: black;
  font-size: 12px;
  overflow: hidden;
}

.grid2 > span {
  padding: 8px 12px;
  color: black;
  font-size: 12px;
}

.license {
  font-weight: bolder;
}

.version {
  font-weight: bolder;
  padding: 0px;
}

.url > a {
  color: #67d6d7;
}

.grid2 > span:nth-child(4) {
  color: black;
  font-weight: 700;
}

.centerh2 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 0px 5px 0px;
  font-size: 30px;
  color: black;
}
.centerh6 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 20px 0px 40px 0px;
  color: black;
  margin: 0px 300px 0px 300px;
  font-weight: 400;
  font-size: 14px;
}

.mainButton {
  padding: 10px;
  background-color: white;
  box-shadow: 5px 5px;
  color: black;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
}

@media screen and (max-width: 720px) {
  .grid1 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-top: 0px solid #c8c8c8;
    border-bottom: 0px solid #c8c8c8;
    background-color: #e9ecef;
    margin: 0px 100px 0px 100px;
    font-size: 8px;
  }
  .grid2 {
    display: grid;
    align-self: center;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 1px solid #c8c8c8;
    background-color: white;
    margin: 0px 100px 0px 100px;
    font-size: 8px;
  }

  .grid1 > span {
    padding: 10px 5px;
    color: black;
    font-size: 8px;
  }

  .grid2 > span {
    padding: 5px 5px;
    color: black;
    font-size: 8px;
  }
  .centerh6 {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px 0px 20px 0px;
    color: black;
    margin: 0px 100px 0px 100px;
    font-weight: 400;
    font-size: 10px;
  }
}
@media screen and (max-width: 450px) {
  .grid1 {
    display: grid;
    align-items: center;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-top: 0px solid #c8c8c8;
    border-bottom: 0px solid #c8c8c8;
    background-color: #e9ecef;
    margin: 0px 100px 0px 100px;
    font-size: 6px;
  }
  .grid2 {
    display: grid;
    align-self: center;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    border-bottom: 1px solid #c8c8c8;
    background-color: white;
    margin: 0px 100px 0px 100px;
    font-size: 6px;
  }

  .grid1 > span {
    padding: 10px 5px;
    color: black;
    font-size: 6px;
  }

  .grid2 > span {
    padding: 5px 5px;
    color: black;
    font-size: 6px;
  }
  .centerh6 {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 10px 0px 20px 0px;
    color: black;
    margin: 0px 100px 0px 100px;
    font-weight: 400;
    font-size: 8px;
  }
  .centerh2 {
    font-size: 20px;
  }
}
</style>
