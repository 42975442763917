<template>
  <v-btn-toggle
    v-if="initialzied"
    id="lang-select-button"
    v-model="languageToggle"
    mandatory
    rounded
    dense
    :color="buttonColor"
    :light="light"
    :border="light"
  >
    <v-btn v-for="(language, index) in languages" :key="index" small>
      {{ language.toUpperCase() }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
import { getStringConfig } from '../utils/config';

const allLanguages = ['en', 'sv', 'de', 'fr'];

export default {
  name: 'LanguageSelector',
  data() {
    return {
      languageToggle: [],
      languages: allLanguages,
      buttonColor: 'secondary',
      initialzied: false,
    };
  },
  props: {
    light: Boolean,
  },
  watch: {
    languageToggle(newValue) {
      this.$i18n.locale = this.languages[newValue];
    },
  },
  async mounted() {
    await this.updateAvailableLanguages();
    const queryLang = this.$route.query.lang;
    if (queryLang && this.languages.includes(queryLang)) {
      this.languageToggle = this.languages.indexOf(queryLang);
    } else {
      this.languageToggle = 0;
    }

    // Set button class and border
    this.buttonColor = this.light ? 'buttonColor' : 'secondary';
    this.initialzied = true;

    this.$emit('hook:initialzied');
  },
  beforeUpdate() {
    if (this.light) {
      const buttonToggle = document.getElementById('lang-select-button');
      if (buttonToggle) {
        // set button class to grey
        buttonToggle.classList.add('grey');
      }
    }
  },
  methods: {
    async updateAvailableLanguages() {
      const avaialbleLanguages = await getStringConfig('AVAILABLE_LANGUAGES');
      if (avaialbleLanguages !== null) {
        const langArr = avaialbleLanguages.split(',');
        if (langArr.length > 0 && langArr.every((lang) => allLanguages.includes(lang))) {
          this.languages = langArr;
        }
      }
    },
  },
};
</script>
