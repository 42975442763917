import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#4D2A96',
        primaryDark: '#41257E',
        secondary: '#A55DFF',
        anchor: '#A55DFF',
        accent: '#5ACD7F',
        error: '#F3787E',
        warning: '#ff9800',
        info: '#607d8b',
        success: '#40D864',
        background: '#301D59',
        blueborder: '#e9ecef',
        primaryWhite: '#fafafa',
        buttonColor: '#67d6d7',
        backgroundWhite: '#ffffff',
      },
    },
  },
});
