<template>
  <div class="d-flex flex-column align-center justify-space-between mt-10">
    <v-progress-circular :color="buttonColor" indeterminate size="64"></v-progress-circular>
  </div>
</template>
<script>
export default {
  name: 'LoadingCircle',
  data() {
    return {
      buttonColor: 'secondary',
    };
  },
  props: {
    light: Boolean,
  },
  mounted() {
    this.buttonColor = this.light ? 'buttonColor' : 'secondary';
  },
};
</script>
