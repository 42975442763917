<template>
  <v-footer width="100%" color="primary" padless marginless>
    <v-row justify="center" no-gutters>
      <!-- <v-btn class="mainButton my-2" v-for="link in links" :key="link" color="white" text rounded>
        <router-link :to="{ name: 'license' }" class="pl-2 text-uppercase">
          {{ $t('licenseLink') }}
        </router-link>
      </v-btn> -->
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  // data: () => ({
  //   links: ['Open Source Licenses'],
  // }),
};
</script>
