import * as configcat from 'configcat-js';

const configCatClient = configcat.getClient(
  process.env.VUE_APP_CONFIG_CAT_KEY,
  configcat.PollingMode.ManualPoll,
);
const REFRESH_CONFIG_TIMEOUT_MS = 1000 * 60; // 60 seconds;
const LAST_REFRESH_EPOCH_STORAGE_KEY = 'LastConfigDownloadEpoch';
const DEBUG_MODE = false;
let refreshInProgress = false;

/**
 * @typedef {'MEDICATION_OPT_OUT'} GloabalFlagName
 * @typedef {'AVAILABLE_LANGUAGES'|'BETA_SIGNUP_REGIONS'|'AVAILABLE_PLATFORMS'} GlobalConfigName
 */

/**
 * Checks if feature is enabled when not logged in
 * @param {GloabalFlagName} featureName
 * @returns {Promise<boolean>}
 */
export async function isGlobalFlagEnabled(featureName) {
  return getBoolValueAsync(featureName, false);
}

/**
 * Checks if feature is enabled when not logged in
 * @param {GlobalConfigName} key
 * @returns {Promise<string|null>}
 */
export async function getStringConfig(key) {
  // @ts-ignore
  return getValueAsync(key, null);
}

/**
 * Get bool value from config cat when not logged in
 * @param {string} key key to get value for
 * @param {boolean} defaultValue default value
 * @returns {Promise<boolean>}
 */
async function getBoolValueAsync(key, defaultValue) {
  const value = await getValueAsync(key, defaultValue);
  return value === true;
}

/**
 * Get any value from config cat when not logged in
 * @param {string} key key to get value for
 * @param {configcat.SettingValue} defaultValue default value
 * @returns {Promise<configcat.SettingValue>}
 */
async function getValueAsync(key, defaultValue) {
  if (shouldRefreshCache() && !refreshInProgress) {
    refreshInProgress = true;
    updateLastConfigDownloadEpoch();
    console.log('Refreshing config cat cache');
    const startTime = new Date().getTime();
    await configCatClient.forceRefreshAsync();
    if (DEBUG_MODE) {
      console.log(`Force refresh done in ${new Date().getTime() - startTime}ms`);
    }
    refreshInProgress = false;
  }
  if (refreshInProgress) {
    if (DEBUG_MODE) {
      console.log('Waiting for refresh to complete');
    }
    while (refreshInProgress) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => setTimeout(resolve, 400));
    }
  }

  const value = await configCatClient.getValueAsync(key, defaultValue);
  if (DEBUG_MODE) {
    console.log(`Got value for ${key}: ${value}`);
  }
  return value;
}

/**
 * Update last config download epoch to now
 */
function updateLastConfigDownloadEpoch() {
  localStorage.setItem(LAST_REFRESH_EPOCH_STORAGE_KEY, new Date().getTime().toString());
}

/**
 * Returns true if cache should be refreshed
 * @returns {boolean}
 */
function shouldRefreshCache() {
  const lastConfigDownloadEpoch = localStorage.getItem(LAST_REFRESH_EPOCH_STORAGE_KEY);
  if (lastConfigDownloadEpoch === null || lastConfigDownloadEpoch === undefined) {
    return true;
  }
  const epochNow = new Date().getTime();
  const timeDiff = epochNow - +lastConfigDownloadEpoch;
  return timeDiff > REFRESH_CONFIG_TIMEOUT_MS;
}
